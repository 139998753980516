import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from "styled-components";

import { theme } from '../theme';
import { Wrap, Button } from '../style/Base';



export const BlockMenu = (props) => {
    const navigate = useNavigate();
  
    const navigateHome = () => {
      navigate('/');
    };
    const navigateResume = () => {
      navigate('/resume');
    };
    const navigateExternal = (url) => {
        window.open(url, '_blank');
      };
  
      const center = {
        left: '50%',
        transform: 'translate(-50%, 0)',
    };

    return (
      <ThemeProvider theme={theme}>
        <Wrap width="100%" zIndex="99999" position="fixed" justifyContent={["center","center","start","start"]}>
      
          <Button fontFamily={[0]} fontSize={[2,2,3,3]} padding={[1]} variant="default" textAlign="left" onClick={navigateHome}> Home </Button>
          <Button fontFamily={[0]} fontSize={[2,2,3,3]} padding={[1]} variant="default"  textAlign="left" onClick={navigateResume}> About </Button>
          <Button fontFamily={[0]} fontSize={[2,2,3,3]} padding={[1]} variant="default" textAlign="left" onClick={() => navigateExternal('https://san-salon.mailchimpsites.com')}> Book </Button>
          <Button fontFamily={[0]} fontSize={[2,2,3,3]} padding={[1]} variant="default" textAlign="left" onClick={() => navigateExternal('https://www.instagram.com/figo_san_salon')}> IG</Button>
          <Button fontFamily={[0]} fontSize={[2,2,3,3]} padding={[1]} variant="default" textAlign="left" onClick={() => navigateExternal('https://www.yelp.com/biz/san-salon-san-gabriel-2')}> Yelp </Button>
      
         </Wrap>
      </ThemeProvider>
    )
  };