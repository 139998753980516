import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import styled from "styled-components"
import { width } from 'styled-system'

const Keen = styled.div`
 height: auto;
 ${width}
`

export const ProductSliderFull = (props) => {
  const [sliderRef] = useKeenSlider({ 
    loop: true,
  },
  
  [
    (slider) => {
      let timeout
      let mouseOver = false
      function clearNextTimeout() {
        clearTimeout(timeout)
      }
      function nextTimeout() {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = setTimeout(() => {
          slider.next()
        }, 4000)
      }
      slider.on("created", () => {
        slider.container.addEventListener("mouseover", () => {
          mouseOver = true
          clearNextTimeout()
        })
        slider.container.addEventListener("mouseout", () => {
          mouseOver = false
          nextTimeout()
        })
        nextTimeout()
      })
      slider.on("dragStarted", clearNextTimeout)
      slider.on("animationEnded", nextTimeout)
      slider.on("updated", nextTimeout)
    },
  ]


  )
  const hasImages = Array.isArray(props.images)

  return (
    <Keen ref={sliderRef} className="keen-slider">
      {hasImages && props.images.map((imageUrl, index) =>
        <div className="keen-slider__slide" key={index} original={imageUrl}>
          <img src={imageUrl} alt={imageUrl} objfit={props.objfit} />
        </div>)}
    </Keen>
  )
}