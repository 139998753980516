import React from 'react'
import { Wrap } from '../style/Base'
import { ProductSliderFull } from '../component/ProductSlider'
import { useTheme } from "styled-components"
import { HomeTicker } from '../component/Ticker'


import pro1 from '../Assets/1.jpg'
import pro2 from '../Assets/2.jpg'
import pro3 from '../Assets/3.jpg'
import pro4 from '../Assets/4.jpg'

import logo1 from '../Assets/logocn.png'
import logo2 from '../Assets/logoen.png'


const profile = [
    pro1,
    pro2,
    pro3,
    pro4
]

const profilem = [
    pro1,
    pro2,
    pro3,
    pro4
]

const en = logo1;

const cn = logo2;



function Home() {

    const theme = useTheme();
    const isMobile = window.innerWidth <= parseInt(theme.breakpoints.md, 10);

    return (
        <div>
            <HomeTicker image1={en} image2={cn}/>

            <Wrap width={['100vw']} height="100vh">
                {isMobile ? (
                    <ProductSliderFull images={profilem} objfit="cover" />
                ) : (
                    <ProductSliderFull images={profile} objfit="cover" />
                )
                }
            </Wrap>
        </div>
    );
}

export default Home;
