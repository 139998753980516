
import React from 'react';
import './App.css';

import Home from './pages/Home'
import Resume from './pages/Resume'
import { BlockMenu } from './component/menu';


import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <BlockMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Resume" element={<Resume />}/>
        </Routes>
        
    </Router>
  );
}

export default App;
