import React from 'react'
import { Wrap, Spacer, IMG } from '../style/Base'
import { List2, ListHeader, List4, ListTitle, List3a, List3b, List3c } from '../component/List'
import Profile from '../Assets/pro02.jpg'

const backgroundStyle = {
    // backgroundImage: `url(${pro1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed', // Make the background image fixed
};

const blur = {
    backgroundColor: "rgba(135, 127, 108, 0.8)"
};

const navigateExternal = (url) => {
    window.open(url, '_blank');
};


function Resume() {

    return (
        <div style={backgroundStyle}>
            <Wrap flexDirection="column" >
                <Wrap flexDirection="column" >

                    <Wrap style={blur} flexDirection="column">
                        <ListHeader title="XUE FEI (FIGO) LIN" Size="5" line="1px rgba(255,255,255,0) solid" color="black" />
                        <Wrap justifyContent="center" p="4">
                            <IMG src={Profile} width="200px" height="300px" borderRadius="100px" />
                        </Wrap>
                        <List2 color="black" line="1px rgba(0,0,0,1) solid" title2={"[Bio]\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Figo Lin is a top hairstylist in China, Active between 2012~2019. He has a passion for hair and works to make sure all of his clients feel special, unique and confident. Striving to bring out the best in all of his clients, he creates an effortless and fresh style with the belief that one haircut doesn't fit all. He utilizes face shape and personal style to create a tailored, current and manageable look."} title1={"[简介]\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Figo Lin是中国顶级发型师。他对头发充满热情，并努力确保所有客户感到特别、独特和自信。他努力让所有客户展现出最好的一面，创造出一种轻松、新鲜的发型，他相信一种发型并不适合所有人。他利用脸型和个人风格来打造量身定制、流行且易于管理的外观。"} />
                    </Wrap>



                    {/* <Wrap style={blur} flexDirection={["column","column","row","row"]}>

                    <List2 color="white" title1={"Style\n\nMen cut\nWomen Cut\nBlowdry"} title2={"Technical\n\nTints\nLighteners\nOmbre\nHighlights\nPerms\nCorrective Colour"} title3={"男士剪发\n女士剪发\n吹干"} line="1px rgba(0,0,0,0.1) solid" />
                    <List2 color="white" title1={"Style\n\n男士剪发\n女士剪发\n吹干"} title2={"Technical\n\n色调\n增亮\n渐变\n高光\n烫发\n校正颜色"} line="1px black solid" />

</Wrap> */}
                    <div style={blur}>
                        <ListTitle color="black" title="Experience" description="" Size="2" line="1px rgba(0,0,0,0.1) solid" />
                        <List3c color="black" line="1px rgba(0,0,0,0.1) solid" title1="2014–Now" title2={["Founder\nSan Salon, LA"]}  title3={"Figo Lin founded this location to offer premimum hair stylist service to mass, Providing quality service while keep it affordable and no compomise into details. VIP-on-demand is also a key service for special events."} />
                        <List3c color="black" line="1px rgba(0,0,0,0.1) solid" title1="2008–2014" title2={["Director(Master Lvl)\nTony Studio, CN"]} title3={"While time at Tony studio, He is mainly taken care show stylist, Providing quality service while keep it affordable and no compomise into details."} line="1px black solid" />
                    
                    </div>

                    <div style={blur}>
                        <ListTitle title="Client" description="" Size="2" line="1px rgba(0,0,0,0.1) solid" />
                        <List2 color="black" line="1px rgba(0,0,0,0.1) solid" title1="Magazine" title2={"BAZAAR, Marie Claire, EIIE, sur face, FIGARO, 时尚COSM0"} />
                        <List2 color="black" line="1px rgba(0,0,0,0.1) solid"  title1="Artist" title2={"黄海波、窦骁、吴秀波、周冬雨、林俊杰、周笔畅、汪涵、刘谦、王宝强"}  />
                        <List2 color="black" line="1px rgba(0,0,0,0.1) solid"  title1="Brand"  title2={"CHANEL. GUGCI,DIOR, VALENTI ON, PRADA,CELINE, ARMANI, BURBERRY. LACOSTE. LANCOME"} />
                        <List2 color="black" line="1px rgba(0,0,0,1) solid"  title1="Masive Events"   title2={"中国国际时装周、2012Chane l新装发布会\n北京春晚会、2010中歌榜、2010湖南卫视跨年演唱会\n快乐男生全国巡演、2009周杰伦北京演唱会\n世界小姐、LYCRA风尚颁奖大典、等活造型工作"} line="1px black solid" />
                    </div>

                  

                    <div style={blur}>
                        <ListHeader title="Let’s Book a Vist.Reach out" line="1px black solid" onClick={() => navigateExternal('https://san-salon.mailchimpsites.com/')} />
                    </div>
                </Wrap>
            </Wrap>
        </div>
    );
}

export default Resume;
