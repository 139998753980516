import Marquee from "react-fast-marquee"
import React from "react"
import { ListHome } from '../component/List'
import {Wrap} from '../style/Base'



export const HomeTicker = ({ title, title2, line, image1, image2 }) => {

const center = {
    transform: 'translate(0, -100%)',
    backdropFilter: "blur(8px)",

};


    return (
        <Wrap position="fixed" zIndex="9999" bg="rgba(255,255,255,1)"  top="100%" left="0" style={center} >
            <Marquee speed={5}>
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
                        <ListHome image={image1} color="black" title3="" title4=''  />
                        <ListHome image={image2} color="black" title3="" title4='' />
            </Marquee>
            </Wrap>
    )
}