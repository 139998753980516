import React from 'react';
import { ThemeProvider } from "styled-components";

import { theme } from '../theme';
import { Wrap, Text, IMG } from '../style/Base';


export const List2 = ({ title1, title2,title3, title4,line, color }) => {
    return (
      <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line}  flexDirection={['column','column','row','row']} >
        <Wrap flexGrow='4' width={["100%","100%","50%","50%"]} flexDirection='column' justifyContent="left">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2" pr="3" fontWeight="400" letterSpacing="-0.5px" variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title1}</Text>
        </Wrap>
        <Wrap flexGrow='4' width={["100%","100%","50%","50%"]} flexDirection='column' justifyContent="left">
          <Text fontFamily={[0]} color={color}  pl={[3]} pt="3" pb="4"  pr="3" fontWeight="400" letterSpacing="-0.5px" variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title2}</Text>

        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }

  export const List3a = ({ title1, title2, title3, line, color }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line} flexWrap="wrap">
        <Wrap  width={["50%","50%","25%","25%"]}  flexDirection='row' justifyContent="left">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title1}</Text>
        </Wrap>
        <Wrap  width={["50%","50%","25%","25%"]} flexDirection='column' justifyContent="start">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title2}</Text>
        </Wrap>
        <Wrap  width={["100%","100%","50%","50%"]} flexDirection='column' justifyContent="left">
          <Text fontFamily={[0]} color={color}  pl={[3]} pt="3" pb="4"   variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title3}</Text>
        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }
  export const List3b = ({ title1, title2, title3, line, color }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line} flexWrap="wrap">
        <Wrap  width={["33.3%","33.3%","50%","25%"]}  flexDirection='row' justifyContent="left">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title1}</Text>
        </Wrap>
        <Wrap  width={["33.3%","33.3%","25%","25%"]} flexDirection='column' justifyContent="start">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title2}</Text>
        </Wrap>
        <Wrap  width={["33.3%","33.3%","25%","25%"]} flexDirection='column' justifyContent="left">
          <Text fontFamily={[0]} color={color}  pl={[3]} pt="3" pb="4"   variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title3}</Text>
        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }

  export const ListTitle = ({ title, description, Size, line, color }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line}>
        <Wrap  flexDirection='row' justifyContent="left">
          <Text fontFamily={[0]}  p={[3]} color={color} fontWeight="600" letterSpacing="-0.5px" variant='' fontSize={Size}>{title}</Text>
        </Wrap>
        <Wrap  flexDirection='row' justifyContent="left">
          <Text fontFamily={[0]}  p={[3]} color={color} fontWeight="600" letterSpacing="-0.5px" variant='' fontSize={Size}>{description}</Text>
        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }

  export const ListHeader = ({ title, Size, line, color }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} justifyContent="center" borderBottom={line}>
      
          <Text fontFamily={[0]}  pt={[5]} pb={[5]} pl={[2]} pr={[2]} textALIGN="center" color={color} fontWeight="600" letterSpacing="-0.5px" variant='' fontSize={Size}>{title}</Text>
       
        </Wrap>
      </ThemeProvider>
    )
  }


  export const List3c = ({ title1, title2, title3, line, color }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line} flexWrap="wrap">
        <Wrap  width={["50%","50%","25%","25%"]}  flexDirection='row' justifyContent="left">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2" pr="3" variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title1}</Text>
        </Wrap>
        <Wrap  width={["50%","50%","25%","25%"]} flexDirection='column' justifyContent="start">
          <Text fontFamily={[0]} color={color} pl={[3]} pt="3" pb="2" pr="3"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title2}</Text>
        </Wrap>
        <Wrap  width={["100%","100%","50%","50%"]} flexDirection='column' justifyContent="left">
          <Text fontFamily={[0]} color={color}  pl={[3]} pt="3" pb="4" pr="3"  variant='' fontSize={[2]} style={{ whiteSpace: 'pre-line' }}>{title3}</Text>
        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }

  export const ListHome = ({ title, title2, title3, title4, line, color, image }) => {
    return (
        <ThemeProvider theme={theme}>
        <Wrap width={["100%"]} borderBottom={line} flexWrap="wrap">
        <Wrap  width={["50%","50%","25%","25%"]}  flexDirection='row' justifyContent="left">
          <IMG width="auto" height={["40px","40px","40px","40px"]} margin={["6px","6px","8px","8px"]} src={image} alt="Image"/>
        </Wrap>
        </Wrap>
      </ThemeProvider>
    )
  }